import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class TermsAppCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>Términos y Condiciones</strong></span>,
            txtTitle=<span><br/>Lea estos Términos y condiciones ("Términos", "Términos y condiciones") detenidamente antes de utilizar la aplicación Apoyo Financiero Mobile (el "Servicio") operado por Apoyo Financiero Inc ("nosotros", "nosotros" o "nuestro") .
                           <br/><br/>Su acceso y uso del Servicio está condicionado a su aceptación y cumplimiento de estos Términos. Estos Términos se aplican a todos los visitantes, usuarios y otras personas que deseen acceder o utilizar el Servicio.
                           <br/><br/>Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los términos, entonces no tiene permiso para acceder al Servicio.<br/><br/></span>,
            txtContent=<span><strong>Compras</strong><br/></span>,
            txtTitleEmp='Última actualización: 14 de agosto de 2018',
            txtContentValors=<span>
                                  Si desea comprar cualquier producto o servicio disponible a través del Servicio ("Compra"), se le puede solicitar que proporcione cierta información relevante para su Compra, que incluye, entre otros, su número de tarjeta de crédito, la fecha de vencimiento de su tarjeta de crédito, su dirección de facturación y su información de envío.
                                  <br/><br/>Usted declara y garantiza que:
                                  <br/>(i) tiene el derecho legal de usar cualquier tarjeta de crédito u otro método de pago en relación con cualquier Compra; y eso
                                  <br/>(ii) la información que nos proporciona es verdadera, correcta y completa.
                                  <br/><br/>El servicio puede emplear el uso de servicios de terceros con el fin de facilitar el pago y la realización de Compras. Al enviar su información, nos otorga el derecho de proporcionar la información a estos terceros sujetos a nuestra Política de privacidad.
                                  <br/><br/>Nos reservamos el derecho de rechazar o cancelar su pedido en cualquier momento por motivos que incluyen, entre otros: disponibilidad del producto o servicio, errores en la descripción o el precio del producto o servicio, error en su pedido u otros motivos.
                                  <br/><br/>Nos reservamos el derecho de rechazar o cancelar su pedido si se sospecha de fraude o de una transacción no autorizada o ilegal.
                             </span>,
            txtContentOne=<span><strong><br/>Disponibilidad, errores e imprecisiones</strong>
                                   <br/><br/>Estamos constantemente actualizando las ofertas de productos y servicios en el Servicio. Es posible que experimentemos demoras en la actualización de la información en el Servicio y en nuestra publicidad en otros sitios web. La información que se encuentra en el Servicio puede contener errores o inexactitudes y puede no estar completa o actualizada. Los productos o servicios pueden tener un precio incorrecto, una descripción incorrecta o no estar disponibles en el Servicio y no podemos garantizar la precisión o integridad de la información que se encuentra en el Servicio.
                                   <br/><br/>Por lo tanto, nos reservamos el derecho de cambiar o actualizar la información y corregir errores, inexactitudes u omisiones en cualquier momento sin previo aviso.</span>,

            txtContentTwo=<span><strong><br/>Concursos, Sorteos y Promociones</strong><br/><br/>Todos los concursos, sorteos u otras promociones (colectivamente, "Promociones") disponibles a través del Servicio pueden regirse por reglas independientes de estos Términos y condiciones. Si participa en alguna Promoción, revise las reglas aplicables, así como nuestra Política de Privacidad. Si las reglas de una Promoción entran en conflicto con estos Términos y Condiciones, se aplicarán las reglas de la Promoción.</span>,

            txtContentFour=<span><strong><br/>Cuentas</strong>
                                   <br/><br/>Cuando crea una cuenta con nosotros, garantiza que tiene más de 18 años y que la información que nos proporciona es precisa, completa y actual en todo momento. La información inexacta, incompleta u obsoleta puede resultar en la terminación inmediata de su cuenta en el Servicio.
                                   <br/><br/>Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, lo que incluye, entre otros, la restricción de acceso a su computadora y/o cuenta. Usted acepta aceptar la responsabilidad por todas y cada una de las actividades o acciones que ocurran bajo su cuenta y/o contraseña, ya sea que su contraseña sea con nuestro Servicio o con un servicio de terceros. Debe notificarnos inmediatamente al darse cuenta de cualquier violación de seguridad o uso no autorizado de su cuenta.
                                   <br/><br/>No puede usar como nombre de usuario el nombre de otra persona o entidad o que no esté legalmente disponible para su uso, un nombre o marca comercial que esté sujeto a cualquier derecho de otra persona o entidad que no sea usted, sin la autorización correspondiente. No puede usar como nombre de usuario ningún nombre que sea ofensivo, vulgar u obsceno.
                                   <br/><br/>Nos reservamos el derecho de rechazar el servicio, cancelar cuentas, eliminar o editar contenido o cancelar pedidos a nuestro exclusivo criterio.</span>,

            txtContentFive=<span><strong><br/>Política de derechos de autor</strong>
                                   <br/><br/>Respetamos los derechos de propiedad intelectual de otros. Es nuestra política responder a cualquier reclamo de que el Contenido publicado en el Servicio infringe los derechos de autor u otros derechos de propiedad intelectual ("Infracción") de cualquier persona o entidad.
                                   <br/><br/>Si es propietario de derechos de autor, o está autorizado en nombre de uno, y cree que el trabajo protegido por derechos de autor ha sido copiado de una manera que constituye una infracción de derechos de autor, envíe su reclamo por correo electrónico a afi@apoyofin.com, con el asunto: "Infracción de derechos de autor" e incluya en su reclamación una descripción detallada de la supuesta infracción como se detalla a continuación, en "Aviso y procedimiento de la DMCA para reclamaciones por infracción de derechos de autor"
                                   <br/><br/>Usted puede ser responsable de los daños (incluidos los costos y los honorarios de los abogados) por declaraciones falsas o reclamos de mala fe sobre la infracción de cualquier Contenido que se encuentre en y/o a través del Servicio sobre sus derechos de autor.</span>,                       

            txtContentSix=<span><strong><br/>Aviso y procedimiento de la DMCA para reclamaciones por infracción de derechos de autor</strong><br/><br/>
                                   Puede enviar una notificación de conformidad con la Ley de derechos de autor del milenio digital (DMCA) proporcionando a nuestro Agente de derechos de autor la siguiente información por escrito (consulte 17 U.S.C 512(c)(3) para obtener más detalles):
                                   <br/><br/>
                              <ul >
                                <li>Una firma electrónica o física de la persona autorizada para actuar en nombre del propietario de los derechos de autor;<br/><br/></li>
                                <li>Una descripción del trabajo protegido por derechos de autor que afirma que se ha infringido, incluida la URL (es decir, la dirección de la página web) de la ubicación donde existe el trabajo protegido por derechos de autor o una copia del trabajo protegido por derechos de autor;<br/><br/></li>
                                <li>Identificación de la URL u otra ubicación específica en el Servicio donde se encuentra el material que usted afirma que está infringiendo;<br/><br/></li>
                                <li>Su dirección, número de teléfono y dirección de correo electrónico;<br/><br/></li>
                                <li>Una declaración suya de que cree de buena fe que el uso en disputa no está autorizado por el propietario de los derechos de autor, su agente o la ley;<br/><br/></li>
                                <li>Una declaración suya, hecha bajo pena de perjurio, de que la información anterior en su notificación es precisa y que usted es el propietario de los derechos de autor o está autorizado para actuar en nombre del propietario de los derechos de autor.<br/><br/></li>
                               </ul>
                                   Puede ponerse en contacto con nuestro agente de derechos de autor por correo electrónico a afi@apoyofin.com   
                                   
                         </span>,  
                         
            txtContent07=<span><strong><br/>Propiedad intelectual</strong><br/><br/>El Servicio y su contenido, características y funcionalidad originales son y seguirán siendo propiedad exclusiva de Apoyo Financiero Inc y sus licenciantes. El Servicio está protegido por derechos de autor, marcas registradas y otras leyes tanto de los Estados Unidos como de otros países. Nuestras marcas comerciales e imagen comercial no se pueden utilizar en relación con ningún producto o servicio sin el consentimiento previo por escrito de Apoyo Financiero Inc.</span>,

            txtContent08=<span><strong><br/>Enlaces a otros sitios web</strong>
                                   <br/><br/>Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por Apoyo Financiero Inc.
                                   <br/><br/>Apoyo Financiero Inc no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web o servicios de terceros. No garantizamos las ofertas de ninguna de estas entidades/individuos o sus sitios web.
                                   <br/><br/>Usted reconoce y acepta que Apoyo Financiero Inc no será responsable, directa o indirectamente, de ningún daño o pérdida causados ​​o presuntamente causados ​​por o en relación con el uso o la confianza en dicho contenido, bienes o servicios disponibles en o a través de dichos sitios web o servicios de terceros.
                                   <br/><br/>Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de cualquier sitio web o servicio de terceros que visite.</span>,

            txtContent09=<span><strong><br/>Terminación</strong>
                                   <br/><br/>Podemos cancelar o suspender su cuenta y prohibir el acceso al Servicio de inmediato, sin previo aviso ni responsabilidad, a nuestro exclusivo criterio, por cualquier motivo y sin limitación, incluido, entre otros, el incumplimiento de los Términos.
                                   <br/><br/>Si desea cancelar su cuenta, simplemente puede dejar de utilizar el Servicio.
                                   <br/><br/>Todas las disposiciones de los Términos que, por su naturaleza, deban sobrevivir a la terminación sobrevivirán a la terminación, incluidas, entre otras, las disposiciones de propiedad, las exenciones de garantía, la indemnización y las limitaciones de responsabilidad.</span>,

            txtContent10=<span><strong><br/>Indemnización</strong><br/><br/>Usted acepta defender, indemnizar y eximir de responsabilidad a Apoyo Financiero Inc y sus licenciatarios y licenciantes, y sus empleados, contratistas, agentes, funcionarios y directores, de y contra cualquier reclamo, daño, obligación, pérdida, responsabilidad, costo o deuda, y gastos (incluidos, entre otros, los honorarios de abogados), que resulten o surjan de a) su uso y acceso al Servicio, por usted o cualquier persona que use su cuenta y contraseña, o b) un incumplimiento de estos Términos.</span>,

            txtContent11=<span><strong><br/>Limitación de responsabilidad</strong><br/><br/>En ningún caso Apoyo Financiero Inc, ni sus directores, empleados, socios, agentes, proveedores o afiliados serán responsables de daños indirectos, incidentales, especiales, consecuentes o punitivos, incluidos, entre otros, la pérdida de ganancias, datos, uso, fondo de comercio u otras pérdidas intangibles, resultantes de (i) su acceso o uso o incapacidad para acceder o usar el Servicio; (ii) cualquier conducta o contenido de un tercero en el Servicio; (iii) cualquier contenido obtenido del Servicio; y (iv) acceso, uso o alteración no autorizados de sus transmisiones o contenido, ya sea en base a garantía, contrato, agravio (incluida la negligencia) o cualquier otra teoría legal, ya sea que hayamos sido informados o no de la posibilidad de dicho daño, e incluso si se determina que un remedio establecido en este documento ha fallado en su propósito esencial.</span>,

            txtContent12=<span><strong><br/>Descargo de responsabilidad</strong>
                                   <br/><br/>Su uso del Servicio es bajo su propio riesgo. El Servicio se proporciona "TAL CUAL" y "SEGÚN DISPONIBILIDAD". El Servicio se proporciona sin garantías de ningún tipo, ya sean expresas o implícitas, incluidas, entre otras, garantías implícitas de comerciabilidad, idoneidad para un propósito particular, no infracción o curso de desempeño.
                                   <br/><br/>Apoyo Financiero Inc, sus subsidiarias, afiliadas y sus licenciantes no garantizan que a) el Servicio funcionará de manera ininterrumpida, segura o disponible en un momento o lugar en particular; b) se corregirán los errores o defectos; c) el Servicio está libre de virus u otros componentes dañinos; o d) los resultados del uso del Servicio cumplirán con sus requisitos.</span>,           

            txtContent13=<span><strong><br/>Exclusiones</strong><br/><br/>Algunas jurisdicciones no permiten la exclusión de ciertas garantías o la exclusión o limitación de responsabilidad por daños indirectos o incidentales, por lo que es posible que las limitaciones anteriores no se apliquen a usted.</span>,

            txtContent14=<span><strong><br/>Ley que rige</strong>
                                   <br/><br/>Estos Términos se regirán e interpretarán de acuerdo con las leyes de California, Estados Unidos, sin tener en cuenta sus disposiciones sobre conflictos de leyes.
                                   <br/><br/>Nuestra incapacidad para hacer cumplir cualquier derecho o disposición de estos Términos no se considerará una renuncia a esos derechos. Si alguna disposición de estos Términos se considera inválida o inaplicable por un tribunal, las disposiciones restantes de estos Términos permanecerán en vigor. Estos Términos constituyen el acuerdo completo entre nosotros con respecto a nuestro Servicio, y reemplazan y reemplazan cualquier acuerdo anterior que hayamos tenido entre nosotros con respecto al Servicio.</span>,           

            txtContent15=<span><strong><br/>Cambios</strong>
                                    <br/><br/>Nos reservamos el derecho, a nuestro exclusivo criterio, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, le proporcionaremos un aviso de al menos 15 días antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material se determinará a nuestro exclusivo criterio.
                                    <br/><br/>Al continuar accediendo o utilizando nuestro Servicio después de que las revisiones entren en vigencia, usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, ya no está autorizado a utilizar el Servicio.</span>,           

            txtContent16=<span><strong><br/>Contacta con nosotros</strong><br/><br/>Si tiene alguna pregunta sobre estos Términos, comuníquese con nosotros.</span>,                        



            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtContentValors}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentOne}                        
                    </Typography>
                    <br/> 
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentTwo}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentFour}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentFive}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentSix}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent07}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent08}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent09}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent10}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent11}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent12}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent13}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent14}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent15}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent16}                        
                    </Typography>
                    <br/>

                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

TermsAppCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    txtContentValors: PropTypes.string,
    txtContentOne: PropTypes.string, 
    txtContentTwo: PropTypes.string, 
        
}

export default withThemeProps(TermsAppCmp, 'VDPNTermsApp')






